const subdomain = window.location.host;

const collaboraLink = subdomain;
export const stripeKey = `pk_live_51MHn49EJpxma8o1MI5c83xKUHzGWCZNiD43W8q1YVmeYjLKd9PO8643s8o65dzVx5V4nmXilA5MzoMOQFGmFlyt500R2oZNIou`;
// export const stripeKey = `pk_test_51MHn49EJpxma8o1MhWPAn6yBo0o4qpMqDKf2bln8Rqe6TodwjPSRiW9QEiDpwbohrA6YBtlWfX8G8v47MmHNUEns00o447ysov`;
export const collaboraDomain = `https://${collaboraLink}`;
// export const apiEndPoint = "https://api.ahlan.work/zoyelapi";
// export const apiEndPoint = "https://api.ahlan.work/ahlanapi";
// export const apiEndPoint = "https://api.zoyel.biz/ahlanapi";
// export const apiEndPoint = "https://api.zoyel.one/z1";
export const apiEndPoint = "https://api.zoyel.health/zoyelapi";

// export const apiEndPoint = "http://localhost:8080/zoyelapi";

// export const socketEndPoint = "https://websocket.zoyel.one";
// export const socketEndPoint = "https://chatserver.ahlan.work";
export const socketEndPoint = "https://chatserver.zoyel.health";
// export const socketEndPoint = "http://localhost:4000";
export const apiEndPointSSE = "https://ahlan.asia/ahlansse";
// export const mailTopicName =
//   "projects/email-client-392717/subscriptions/test-push-sub";
// export const mailTopicName = "projects/zoyel-app/topics/zoyel_mail";

//************** For chatserver.ahlan.work ******************//
// export const mailTopicName =
//   "projects/omega-will-309312/topics/ahlan_mail_topic";

//************** For webScoket.zoyel.one ******************//

export const mailTopicName = "projects/zoyel-app-9272528/topics/zoyel-app-mail";

export const jitsiDomain = "https://video.zoyel.one";
export const jitsiHostName = "video.zoyel.one";

// export const jitsiDomain = "https://video.ahlan.work";
// export const jitsiHostName = "video.ahlan.work";

let host = window.location.host.replace("www.", "");
const domainArray = host.split(".");
export const publicUrl = domainArray.length === 3 ? "/app" : "";
export const webApp = "zoyel.health";
