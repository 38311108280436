import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "../css/profile.css";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import {
  InfoCircle,
  PersonBoundingBox,
  Plus,
  XLg,
} from "react-bootstrap-icons";
import userimg from "../images/user.jpg";
import {
  Autorenew,
  CameraAlt,
  Check,
  CheckCircle,
  Close,
  Done,
  Help,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import RadialProgressBar from "./component/RadialProgressBar";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardServices from "../../../services/DashboardServices";
import { useForm } from "react-hook-form";
import { getLoginUserDetails, validateEmail } from "../../../utils/Utils";
import { EditPencil } from "iconoir-react";
import { toast } from "react-toastify";
import { UploadProfileImage } from "./UploadProfileImage";
import FaceCaptureComponent from "./FaceCaptureComponent";
import { ConfirmProvider } from "material-ui-confirm";
import DateUtils from "../../../utils/DateUtils";
import styled from "styled-components";
import { convertFileToBase64_Split } from "../../../utils/FileUtils";
import SignStamp from "./SignStamp";
import ProfileService from "../../../services/ProfileService";
import { AddTabContext } from "../../dashboard/view/DashboardPage";
import AppUtils from "../../../utils/AppUtils";
export default function Profile({
  profileData,
  closeprofile,
  refreshUserData,
}) {
  const { refreshTabs } = useContext(AddTabContext);
  const { control, setValue, register } = useForm();
  const [startDOJ, setStartDOJ] = useState(new Date());
  const [empoloyeeId, setEmployeeId] = useState("");
  const [department, setDepartment] = useState("");
  // const [designation, setDesignation] = useState("");
  const [position, setPosition] = useState("");
  const [joiningDate, setJoiningDate] = useState(new Date());
  const [reportingHead, setReportingHead] = useState("");
  const [userLanguageList, setUserLanguageList] = useState([]);
  const [languageMasterList, setLanguageMasterList] = useState([]);
  const [personalEmailId, setPersonalEmailId] = useState("");
  const [Officialemail, setOfficialEmail] = useState("");
  const [skill, setSkill] = useState("");
  const [enableSkill, setEnableSkill] = useState(true);
  const [enableMeritalStatus, setEnableMaritalStatus] = useState(true);
  const [emergencyContactNo, setEmergencyNumber] = useState("");
  const [enablePersonalContact, setEnablePersonalContact] = useState(true);
  const [enableOfficialContact, setEnableOfficialContact] = useState(true);
  const [enablePersonalEmail, setEnablePersonalEmail] = useState(true);
  const [enableEmergencyContact, setEnableEmergencyContract] = useState(true);
  const [enableLanguage, setEnableLanguage] = useState(true);
  const [enableUserName, setEnableUserName] = useState(true);
  const [enableDateOfBirth, setEnableDateOfBirth] = useState(true);
  // const [enableSignature, setEnableSignature] = useState(true);
  const [Signature, setSignature] = useState("");
  const signImageRef = useRef(null);
  // const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmnPassword, setConfirmPassword] = useState("");
  const [showDobInline, setShowDobInline] = useState(false);

  const [showUploadProfileModal, setShowUploadProfileModal] = useState(false);

  const hasSpecialCharacter = useMemo(() => {
    var regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    return regex.test(newPassword);
  }, [newPassword]);

  const hasSmallCharacter = useMemo(() => {
    var regex = /[a-z]/;
    return regex.test(newPassword);
  }, [newPassword]);

  const hasCapital = useMemo(() => {
    var regex = /[A-Z]/;
    return regex.test(newPassword);
  }, [newPassword]);

  const hasNumber = useMemo(() => {
    var regex = /\d/;
    return regex.test(newPassword);
  }, [newPassword]);

  const passwordCorrectLength = useMemo(() => {
    return newPassword.length >= 8 && newPassword.length <= 20;
  }, [newPassword]);

  const matchNewAndConfirmPassword = useMemo(() => {
    if (newPassword === "" && confirmnPassword === "") {
      return false;
    }
    return newPassword === confirmnPassword;
  }, [newPassword, confirmnPassword]);

  const [startDOB, setStartDOB] = useState(null);
  const currentSkill = useRef("");

  const [maritalStatus, setMaritalStatus] = useState("");

  const handlemaritalStatus = (event) => {
    setMaritalStatus(event.target.value);
  };

  const [enableDefaultLandingTab, setEnableDefaultLandingTab] = useState(true);

  const [defaultLandingTab, setDefaultLandingTab] = useState("");

  const [personalContactNo, setPersonalContactNo] = useState("");
  const [officialContactNo, setOfficialContactNo] = useState("");

  const calculatePercentage = () => {
    let fillupCount = 0;
    if (userProfileDto) {
      if (userProfileDto.dob && userProfileDto.dob !== "") {
        fillupCount++;
      }
      if (
        userProfileDto.languageCodes &&
        userProfileDto.languageCodes.length > 0
      ) {
        fillupCount++;
      }
      if (userProfileDto.skills && userProfileDto.skills !== "") {
        fillupCount++;
      }
      if (userProfileDto.martialStatus && userProfileDto.martialStatus !== "") {
        fillupCount++;
      }
      if (
        userProfileDto.personalContactNo &&
        userProfileDto.personalContactNo !== ""
      ) {
        fillupCount++;
      }
      if (
        userProfileDto.personalEmailId &&
        userProfileDto.personalEmailId !== ""
      ) {
        fillupCount++;
      }
      if (
        userProfileDto.emergencyContactNo &&
        userProfileDto.emergencyContactNo !== ""
      ) {
        fillupCount++;
      }
      if (userProfileDto.userName && userProfileDto.userName !== "") {
        fillupCount++;
      }

      if (loginUserDetails.orgId !== "ORG000000000000") {
        // if (
        //   userProfileDto.officialEmailId &&
        //   userProfileDto.officialEmailId !== ""
        // ) {
        //   fillupCount++;
        // }

        if (
          userProfileDto.officialContactNo &&
          userProfileDto.officialContactNo !== ""
        ) {
          fillupCount++;
        }
        if (
          userProfileDto.reportingHead &&
          userProfileDto.reportingHead !== ""
        ) {
          fillupCount++;
        }
        if (userProfileDto.joiningDate && userProfileDto.joiningDate !== "") {
          fillupCount++;
        }
        if (userProfileDto.position && userProfileDto.position !== "") {
          fillupCount++;
        }
        if (userProfileDto.department && userProfileDto.department !== "") {
          fillupCount++;
        }
      }
    }

    const total = loginUserDetails.orgId === "ORG000000000000" ? 8 : 13;
    const percent = Math.floor((fillupCount / total) * 100);
    return percent;
  };

  const handlePersonalContactNo = (contactNo) => {
    const formattedPhoneNumber = contactNo.replace(/\D/g, "").slice(0, 12);
    const formattedWithDashes = formattedPhoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3"
    );
    setPersonalContactNo(formattedWithDashes);
  };

  const handleOfficialContactNo = (contactNo) => {
    const formattedPhoneNumber = contactNo.replace(/\D/g, "").slice(0, 12);
    const formattedWithDashes = formattedPhoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3"
    );
    setOfficialContactNo(formattedWithDashes);
  };

  const handleUpdateSignature = async (e) => {
    try {
      if (!e.target.files) {
        return;
      }
      const file = e.target.files[0];
      console.log("handleFileUpload", file);

      const validTypes = ["image/png", "image/jpg", "image/jpeg"];
      if (!validTypes.includes(file.type)) {
        toast.error("Invalid file format. Allowed formats: PNG, JPG, JPEG.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB < 2 || fileSizeInKB > 200) {
        toast.error("File size must be between 10KB and 200KB.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = async () => {
        const { width, height } = image;
        if (height < 50 || height > 512) {
          toast.error("Image height must be between 50px and 512px.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }

        const fileData = await convertFileToBase64_Split(file);
        const fileName = file.name;

        if (fileData && fileName) {
          console.log("fileData", fileData);
          console.log("fileName", fileName);
          const reqDto = {
            fileName: fileName,
            fileData: fileData,
          };

          const response = await DashboardServices.updateSignature(
            loginUserDetails.userId,
            loginUserDetails.orgId,
            reqDto
          );

          console.log(response.data);
          setSignature(response.data);
          toast.success("Profile Signature Uploaded Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };
    } catch (error) {
      console.error("Error occurred during file upload:", error);
      toast.error(
        "An error occurred during file upload. Please try again later.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  // const handleUpdateSignature = async (e) => {
  //   try {
  //     console.log(e);
  //     if (!e.target.files) {
  //       return;
  //     }
  //     const file = e.target.files[0];
  //     console.log("handleFileUpload", file);

  //     const fileData = await convertFileToBase64_Split(file);
  //     const fileName = file.name;

  //     if (fileData && fileName) {
  //       console.log("fileData", fileData);
  //       console.log("fileName", fileName);
  //       const reqDto = {
  //         fileName: fileName,
  //         fileData: fileData,
  //       };

  //       const response = await DashboardServices.updateSignature(
  //         loginUserDetails.userId,
  //         loginUserDetails.orgId,
  //         reqDto
  //       );

  //       console.log(response.data);
  //       setSignature(response.data);
  //       toast.success("Profile Signature Uploaded Successfully", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error occurred during file upload:", error);
  //     toast.error(
  //       "An error occurred during file upload. Please try again later.",
  //       {
  //         position: toast.POSITION.TOP_RIGHT,
  //       }
  //     );
  //   }
  // };

  const handleEmergencyNumberChange = (e) => {
    const formattedPhoneNumber = e.target.value.replace(/\D/g, "").slice(0, 12);
    const formattedWithDashes = formattedPhoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3"
    );
    setEmergencyNumber(formattedWithDashes);
  };

  const [selectEmailType, setSelectEmailType] = useState("Officialemail");

  const handleemailId = (event) => {
    setSelectEmailType(event.target.value);
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [showResetPassBtn, setResetPassBtn] = useState(true);
  const [showUpdatePassBtn, setUpdatePassBtn] = useState(false);
  const [showResetPassContainer, setResetPassContainer] = useState(false);
  const handelShowResetPassContainer = () => {
    setResetPassContainer(!showResetPassContainer);
    setUpdatePassBtn(!showUpdatePassBtn);
    setResetPassBtn(false);
  };
  const [showRegFace, setShowRegFace] = useState(false);
  const [faceId, setFaceId] = useState(null);

  function validatePassword(password, confirmPassword) {
    // Check minimum length
    if (password.length < 8) {
      toast.error("The password should be at least 8 characters.");
      return false;
    }

    // Check maximum length
    if (password.length > 20) {
      toast.error("The password should be at most 20 characters.");
      return false;
    }

    // Check for at least one special character
    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
      toast.error(
        "The password should contain at least one special character."
      );
      return false;
    }

    // Check for at least one numeric letter
    if (!/\d/.test(password)) {
      toast.error("The password should contain at least one numeric letter.");
      return false;
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      toast.error("The password should contain at least one uppercase letter.");
      return false;
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      toast.error("The password should contain at least one lowercase letter.");
      return false;
    }

    // Check if the new password and confirm password match
    if (password !== confirmPassword) {
      toast.error("New Password and Confirm Password should match.");
      return false;
    }

    return true;
  }
  // const handelUpdateAndshowResetpassBtn = () => {
  //   const valid = validatePassword(newPassword, confirmnPassword);
  //   if (valid) {
  //     handelSubmitFields("password");
  //   }
  //   setResetPassBtn(!showResetPassBtn);
  //   setUpdatePassBtn(false);
  //   setResetPassContainer(false);
  // };

  const handlePaste = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handelUpdateAndshowResetpassBtn = () => {
    console.log("oldPassword", oldPassword);
    console.log("newPassword", newPassword);
    console.log("confirmnPassword", confirmnPassword);

    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const numberRegex = /\d/;

    if (newPassword !== confirmnPassword) {
      toast.error("New password and confirm password should be the same", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (newPassword === oldPassword) {
      toast.error("New password should not match the old password", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (newPassword.length < 8) {
      toast.error("Minimum 8 characters required in password", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (newPassword.length > 20) {
      toast.error("Maximum 20 characters allowed in password", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (!uppercaseRegex.test(newPassword)) {
      toast.error("Password must contain at least one uppercase letter", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (!lowercaseRegex.test(newPassword)) {
      toast.error("Password must contain at least one lowercase letter", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (!specialCharRegex.test(newPassword)) {
      toast.error("Password must contain at least one special character", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (!numberRegex.test(newPassword)) {
      toast.error("Password must contain at least one digit", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else {
      handelSubmitFields("password");
    }
    setResetPassBtn(!showResetPassBtn);
    setUpdatePassBtn(false);
    setResetPassContainer(false);
  };

  // autocomplete option
  const languagesList = [
    { label: "Hindi" },
    { label: "English" },
    { label: "Bengali" },
    { label: "Arbic" },
    { label: "Urdu" },
    { label: "Spanish" },
  ];
  const skillsList = [
    { label: "Service Level Management" },
    { label: "Quality Management" },
    { label: "Maintenance and Repairs" },
    { label: "System Security" },
    { label: "Inventory Management" },
    { label: "Applications Support" },
  ];

  // const updateData = async (dto) => {
  //   let retVal = false;
  //   if (dto.field !== "" && dto.fieldValue !== "") {
  //     await DashboardServices.updateField(loginUserDetails.userId, dto).then(
  //       (response) => {
  //         ;
  //         // console.log(retu)
  //         retVal = response.data.returnValue === "1" ? true : false;
  //         if (response.data.returnValue === "1") {
  //           toast.success(response.data.message, {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //           fetchUserProfileDetails();
  //         } else {
  //           toast.error(response.data.message, {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //         }
  //       }
  //     );
  //   }
  //   return retVal;
  // };

  const updateData = async (dto) => {
    if (dto.field !== "" && dto.fieldValue !== "") {
      try {
        const response = await DashboardServices.updateField(
          loginUserDetails.userId,
          dto
        );

        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchUserProfileDetails();
          return true;
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          return false;
        }
      } catch (error) {
        toast.error("An error occurred while updating the data.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error("API call failed: ", error);
        return false;
      }
    }
  };

  const handelSubmitFields = async (source) => {
    if (source === "userName") {
      if (
        firstName === userProfileDto.firstName &&
        middleName === userProfileDto.middleName &&
        lastName === userProfileDto.lastName
      ) {
        toast.error("No Changes Made", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEnableUserName(true);
        return;
      }
      DashboardServices.updateUserName(loginUserDetails.userId, {
        firstName,
        middleName,
        lastName,
      }).then((response) => {
        if (response.data) {
          if (response.data.returnValue === "1") {
            setEnableUserName(true);
            fetchUserProfileDetails();
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    }

    if (source === "dateOfBirth") {
      console.log(startDOB.value);
      if (
        null != userProfileDto.dob &&
        null != startDOB &&
        userProfileDto.dob === DateUtils.getDateInDDMMYYYY(startDOB)
      ) {
        toast.error("No Changes Made", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEnableDateOfBirth(true);
        return;
      }
      if ((null == startDOB || "" === startDOB) && null == userProfileDto.dob) {
        const reqDto = [
          {
            field: "date_of_birth",
            fieldValue: DateUtils.getDateInDDMMYYYY(new Date()),
          },
        ];
        console.log("startDOB", reqDto);
        if (updateData(reqDto)) {
          setEnableDateOfBirth(true);
        }
      } else {
        const reqDto = [
          {
            field: "date_of_birth",
            fieldValue: DateUtils.getDateInDDMMYYYY(startDOB),
          },
        ];
        console.log("startDOB", reqDto);
        if (updateData(reqDto)) {
          setEnableDateOfBirth(true);
        }
      }
    }

    if (source === "martialStatus") {
      if (userProfileDto.martialStatus === null) {
        userProfileDto.martialStatus = "";
      }
      if (userProfileDto.martialStatus === maritalStatus) {
        toast.error("No Changes Made", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEnableMaritalStatus(true);
        // setMaritalStatus((martialStatus) => ({
        //   ...martialStatus,
        //   ...{ status: false },
        // }));
        return;
      }
      if (userProfileDto.martialStatus !== maritalStatus) {
        const reqDto = [
          {
            field: "martial_status",
            fieldValue: maritalStatus,
          },
        ];
        if (updateData(reqDto)) {
          setMaritalStatus(maritalStatus);
          setEnableMaritalStatus(true);
        }
      } else {
        setEnableMaritalStatus(true);
      }
    }

    if (source === "officialContactNo") {
      if (userProfileDto.officialContactNo === null) {
        userProfileDto.officialContactNo = "";
      }
      if (officialContactNo === userProfileDto.officialContactNo) {
        toast.error("No Changes Made", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEnableOfficialContact(true);
        return;
      }
      if (officialContactNo.length < 10) {
        toast.error("Official Contact No Can't Be Less Than 10 Character Long");
        return;
      }
      if (officialContactNo !== userProfileDto.officialContactNo) {
        const reqDto = [
          {
            field: "official_contact_no",
            fieldValue: officialContactNo,
          },
        ];
        if (updateData(reqDto)) {
          setEnableOfficialContact(true);
        }
      } else {
        setEnableOfficialContact(true);
      }
    }

    if (source === "personalContactNo") {
      if (userProfileDto.personalContactNo === null) {
        userProfileDto.personalContactNo = "";
      }
      if (userProfileDto.personalContactNo === personalContactNo) {
        toast.error("No Changes Made", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEnablePersonalContact(true);
        return;
      }
      if (personalContactNo.length < 10) {
        toast.error("Contact No Can't Be Less Than 10 Character Long");
        return;
      }
      if (userProfileDto.personalContactNo !== personalContactNo) {
        const reqDto = [
          {
            field: "personal_contact_no",
            fieldValue: personalContactNo,
          },
        ];
        if (updateData(reqDto)) {
          setEnablePersonalContact(true);
        }
      } else {
        setEnablePersonalContact(true);
      }
    }

    if (source === "emergencyContactNo") {
      if (userProfileDto.emergencyContactNo === null) {
        userProfileDto.emergencyContactNo = "";
      }
      if (userProfileDto.emergencyContactNo === emergencyContactNo) {
        toast.error("No Changes Made", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEnableEmergencyContract(true);
        return;
      }
      if (emergencyContactNo.length < 10) {
        toast.error("Contact No Can't Be Less Than 10 Character Long");
        return;
      }
      if (userProfileDto.emergencyContactNo !== emergencyContactNo) {
        const reqDto = [
          {
            field: "emergency_contact_no",
            fieldValue: emergencyContactNo,
          },
        ];
        if (updateData(reqDto)) {
          setEnableEmergencyContract(true);
        }
      } else {
        setEnableEmergencyContract(true);
      }
    }

    if (source === "personalEmailId") {
      if (userProfileDto.personalEmailId === null) {
        userProfileDto.personalEmailId = "";
      }
      if (userProfileDto.personalEmailId === personalEmailId) {
        toast.error("No Changes Made", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEnablePersonalEmail(true);

        return;
      }
      if (validateEmail(personalEmailId)) {
      }
      if (userProfileDto.personalEmailId !== personalEmailId) {
        const reqDto = [
          {
            field: "personal_mail_id",
            fieldValue: personalEmailId,
          },
        ];
        if (updateData(reqDto)) {
          setEnablePersonalEmail(true);
        }
      } else {
        setEnablePersonalEmail(true);
      }
    }
    if (source === "skills") {
      if (userProfileDto.skills === skill) {
        toast.error("No Changes Made", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEnableSkill(true);
        return;
      }

      const reqDto = [
        {
          field: "skills",
          fieldValue: skill,
        },
      ];
      const updateValue = await updateData(reqDto);
      if (updateValue) {
        setEnableSkill(true);

        // setSkill(null);
      } else {
        // setEnableSkill(true);
        setSkill(currentSkill.current);
        currentSkill.current = "";
      }
    }

    if (source === "password") {
      // const validType = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
      // if (password.newpassword !== password.confirmpassword) {
      //   toast.error("New Password And Confirm Password Not Match !", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // } else if (!password.newpassword.match(validType)) {
      //   toast.error("Please Use a Strong Password", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // } else {
      const reqDto = {
        userId: loginUserDetails.userId,
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      DashboardServices.changePassword(reqDto).then((response) => {
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setUpdatePassBtn(false);
          setResetPassBtn(true);
          setResetPassContainer(false);
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      // }
    }
  };

  const loginUserDetails = getLoginUserDetails();
  const [userProfileDto, setUserProfileDto] = useState();

  const handleLanguageSubmit = () => {
    if (
      JSON.stringify(userProfileDto.languageCodes) !==
      JSON.stringify(userLanguageList)
    ) {
      if (userLanguageList.length > 3) {
        toast.error(`You Can't add more then 3 Languages`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        let tmpCodeList = [];

        for (let i = 0; i < userLanguageList.length; i++) {
          const { id } = userLanguageList[i];
          tmpCodeList.push(id);
        }

        DashboardServices.addUpdateUserLanguage(
          loginUserDetails.userId,
          tmpCodeList
        ).then((response) => {
          if (response.data) {
            if (response.data.returnValue === "1") {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              fetchUserProfileDetails();
              setEnableLanguage(true);
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
      }
    } else {
      toast.error(`No Changes Made`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEnableLanguage(true);
    }
  };

  const getDateFromString = (dateStr) => {
    try {
      const parts = dateStr.split("-");

      var day = parseInt(parts[0], 10);
      var month = parseInt(parts[1], 10) - 1; // Months are zero-based
      var year = parseInt(parts[2], 10);

      var dateObject = new Date();
      dateObject.setDate(day);
      dateObject.setMonth(month);
      dateObject.setFullYear(year);

      return dateObject;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserProfileDetails = () => {
    DashboardServices.getUserProfile(loginUserDetails.userId).then(
      (response) => {
        if (!response.data) {
          return;
        }
        const eData = response.data;
        console.log("user profile data=", response.data);
        setUserProfileDto(response.data);
        setDepartment(response.data.department);
        setPosition(response.data.positions);
        setEmployeeId(response.data.employeeId);
        setReportingHead(eData.reportingHead);
        setMaritalStatus(eData.martialStatus);
        setEmergencyNumber(eData.emergencyContactNo);
        setPersonalEmailId(eData.personalEmailId);
        setOfficialEmail(eData.officialEmailId);
        setFirstName(eData.firstName);
        setLastName(eData.lastName);
        setMiddleName(eData.middleName);
        setSkill(eData.skills);
        handlePersonalContactNo(eData.personalContactNo);
        handleOfficialContactNo(eData.officialContactNo);
        setSignature(response.data.signFileLink);

        if (eData && eData.dob && eData.dob !== "") {
          // setStartDOB(dayjs(eData.dob, { format: "DD-MM-YYYY" }));
          setStartDOB(getDateFromString(eData.dob));
          // setStartDOB(new Date());
        }

        if (loginUserDetails.orgId !== "ORG000000000000") {
          setJoiningDate(getDateFromString(eData.joiningDate));
        }

        // setUserName((userName) => ({
        //   ...userName,
        //   ...{
        //     firstName: response.data.firstName,
        //     middleName: response.data.middleName,
        //     lastName: response.data.lastName,
        //   },
        // }));
        // setSkills(response.data.skills);
        // if (null != response.data.dob) {
        //   setSelectedDate(
        //     dayjs(
        //       new Date(
        //         new Date(DateUtils.convertToDate(response.data.dob)).setHours(
        //           0,
        //           0,
        //           0
        //         )
        //       )
        //     )
        //   );
        //   setDateOfBirth((startDOB) => ({
        //     ...dateOfBirth,
        //     ...{
        //       value: dayjs(
        //         new Date(
        //           new Date(DateUtils.convertToDate(response.data.dob)).setHours(
        //             0,
        //             0,
        //             0
        //           )
        //         )
        //       ).$d,
        //     },
        //   }));
        // }

        // setUserLanguageList([]);

        setValue("employeeId", response.data.employeeId);
      }
    );
    DashboardServices.getFaceId(loginUserDetails.userId).then((response) => {
      if (response.data) {
        setFaceId(response.data);
      }
    });
  };

  useEffect(() => {
    if (languageMasterList && languageMasterList.length > 0 && userProfileDto) {
      setUserLanguageList(
        languageMasterList.filter((language) =>
          userProfileDto.languageCodes.some(
            (usrLang) => usrLang.id === language.id
          )
        )
      );
    }
  }, [userProfileDto, languageMasterList]);

  useEffect(() => {
    DashboardServices.getBcp47CodeName().then((response) => {
      if (response.data) {
        setLanguageMasterList(response.data);
      }
    });
    if (loginUserDetails.userType !== "GUEST") {
      fetchUserProfileDetails();
    }
    ProfileService.getDefaultLandingTab(loginUserDetails.userId).then(
      (response) => {
        if (response.data) {
          setDefaultLandingTab(response.data);
        }
      }
    );
  }, []);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} disableFocusListener />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#4c4c4c",
      color: "white",
      maxWidth: 300,
      fontSize: "14px",
      border: "1px solid #4c4c4c",
    },
  }));

  // get stamp date time and timezone
  // const [stampDate, setStampDate] = useState(new Date());

  // useEffect(() => {
  //   const updateStampDateTime = () => {
  //     setStampDate(new Date());
  //   };
  //   const intervalId = setInterval(updateStampDateTime, 1000);
  //   return () => clearInterval(intervalId);
  // }, []);

  // const formattedDate = stampDate.toLocaleDateString("en-US", {
  //   day: "numeric",
  //   month: "short",
  //   year: "numeric",
  // });
  // const formattedTime = stampDate.toLocaleTimeString([], {
  //   hour: "2-digit",
  //   minute: "2-digit",
  // });

  const modifyDefaultLandingTab = async () => {
    const response = await ProfileService.modifyDefaultLandingTab(
      loginUserDetails.userId,
      defaultLandingTab
    );
    if (response.data.returnValue === "1") {
      toast.success(response.data.message);
      setEnableDefaultLandingTab(true);
      setDefaultLandingTab(defaultLandingTab);
      refreshTabs();
    } else {
      toast.error(response.data.message);
    }
  };

  return (
    <>
      <div className="flotingProfilecontainer">
        <div className="profileAreaContainer">
          <div className="profileheader">
            <div className="proheadNm">Profile</div>
            <IconButton className="CloseBtn " onClick={closeprofile}>
              <XLg />
            </IconButton>
          </div>
          <div className="profileDtlContainer">
            <div className="profileDtlContainerArea">
              <div className="proUserInfo">
                <div className="proImgwthControl">
                  <div className="proUserImg">
                    <img
                      src={`${
                        userProfileDto
                          ? `data:image/png;base64,${userProfileDto.profileImageFileDataLink}`
                          : profileData
                          ? `data:image/png;base64,${profileData}`
                          : "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png"
                      }`}
                      alt=""
                    />
                  </div>
                  <div
                    className="changeUserImg"
                    onClick={(e) => {
                      console.log("user image changed");
                      setShowUploadProfileModal(true);
                    }}
                  >
                    <span>
                      <CameraAlt />
                    </span>
                    {/* <input
                      className="inputChangeImg"
                      type="file"
                      name="change Img"
                    /> */}
                  </div>
                </div>
                <div className="proUserDtlStat">
                  <div className="proUser_status">
                    <div className="proUserNmDeg">
                      <div className="fromWithEdtUpldBtn">
                        <div className="proUserName">
                          {userProfileDto && userProfileDto.userName}
                        </div>
                        {/* {!enableUserName && (
                        <TextField
                          label="First Name"
                          variant="outlined"
                          className="formTextFieldArea"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                          // value={userProfileDto && userProfileDto.employeeId}
                          // {...register("employeeId")}
                        />
                      )}
                      {!enableUserName && (
                        <TextField
                          label="Second Name"
                          variant="outlined"
                          className="formTextFieldArea"
                          value={middleName}
                          onChange={(e) => {
                            setMiddleName(e.target.value);
                          }}
                          // value={userProfileDto && userProfileDto.employeeId}
                          // {...register("employeeId")}
                        />
                      )}
                      {!enableUserName && (
                        <TextField
                          label="Last Name"
                          variant="outlined"
                          className="formTextFieldArea"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          // value={userProfileDto && userProfileDto.employeeId}
                          // {...register("employeeId")}
                        />
                      )} */}
                        {enableUserName && (
                          <div className="appendNewRowBtn">
                            <IconButton
                              className="elemntIconBtn"
                              onClick={(e) => {
                                console.log("changing user name ");
                                if (!enableUserName) {
                                  handelSubmitFields("userName");
                                  // setEnableUserName((prev) => !prev);
                                } else {
                                  setEnableUserName((prev) => !prev);
                                }
                              }}
                            >
                              {!enableUserName ? <Check /> : <EditPencil />}
                            </IconButton>
                          </div>
                        )}
                      </div>

                      <div className="ProUsrDesignation">
                        {userProfileDto && userProfileDto.position}
                      </div>
                    </div>
                    <div className="proCompStat">
                      <RadialProgressBar
                        profileProgress={
                          userProfileDto ? calculatePercentage() : 0
                        }
                      />
                    </div>
                  </div>
                  <div className="proUserSkills">
                    {userProfileDto && userProfileDto.skills}
                  </div>
                  <div className="proUserRegFace">
                    <Button
                      className="dfultPrimaryBtn "
                      startIcon={<PersonBoundingBox />}
                      onClick={() => {
                        setShowRegFace(true);
                      }}
                    >
                      Register Face
                    </Button>
                    <HtmlTooltip
                      title="Photosensitivity warning
                            This check flashes different colors. 
                            Use caution if you are photosensitive. Some people may experience epileptic seizures when exposed to colored lights. Use caution if you, or anyone in your family, have an epileptic condition.
                                "
                    >
                      <IconButton>
                        <InfoCircle />
                      </IconButton>
                    </HtmlTooltip>
                  </div>
                </div>
              </div>
              <div className="profileFromContainer">
                <div className="profileFormElementGroup fromWithEdtUpldBtn">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectMaritalStatus">
                        Default landing tab
                      </InputLabel>
                      <Select
                        disabled={enableDefaultLandingTab}
                        className="formInputField"
                        variant="outlined"
                        labelId="selectMaritalStatus"
                        id="MaritalStatus"
                        value={defaultLandingTab}
                        label="Marital Status"
                        onChange={(event) => {
                          setDefaultLandingTab(event.target.value);
                        }}
                      >
                        {!AppUtils.isPersonal(loginUserDetails) && (
                          <MenuItem value={"DASHBOARD"}> Dashboard</MenuItem>
                        )}
                        <MenuItem value={"INBOX"}> Inbox </MenuItem>
                        <MenuItem value={"APPS"}> Apps </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="appendNewRowBtn">
                    <IconButton
                      className="elemntIconBtn"
                      onClick={(e) => {
                        if (!enableDefaultLandingTab) {
                          // setEnableDefaultLandingTab((prev) => !prev);
                          // handelSubmitFields("dateOfBirth");
                          modifyDefaultLandingTab();
                        } else {
                          setEnableDefaultLandingTab((prev) => !prev);
                        }
                      }}
                    >
                      {!enableDefaultLandingTab ? <Check /> : <EditPencil />}
                    </IconButton>
                  </div>
                </div>
              </div>

              <div className="profileFromContainer">
                <div className="promptMsg">
                  Click Pencil Button to edit and save
                </div>

                {loginUserDetails.orgId !== "ORG000000000000" && (
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Employee Id*"
                        variant="outlined"
                        className="formTextFieldArea"
                        value={empoloyeeId}
                        // value={userProfileDto && userProfileDto.employeeId}
                        // {...register("employeeId")}
                        disabled
                      />
                    </FormControl>
                  </div>
                )}
                {loginUserDetails.orgId !== "ORG000000000000" && (
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Department"
                        variant="outlined"
                        className="formTextFieldArea"
                        value={department}
                        disabled
                      />
                    </FormControl>
                  </div>
                )}
                {loginUserDetails.orgId !== "ORG000000000000" && (
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Position*"
                        variant="outlined"
                        className="formTextFieldArea"
                        value={position}
                        disabled
                      />
                    </FormControl>
                  </div>
                )}
                {loginUserDetails.orgId !== "ORG000000000000" && (
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="setjoin" className="setCompletionDate">
                        Date of Joining
                      </InputLabel>
                      <ReactDatePicker
                        showIcon
                        labelId="setjoin"
                        className="formDatePicker"
                        dateFormat="dd-MMM-yyyy"
                        selected={joiningDate}
                        onChange={(date) => setStartDOJ(date)}
                        disabled
                      />
                    </FormControl>
                  </div>
                )}

                {loginUserDetails.orgId !== "ORG000000000000" && (
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Reporting Manager*"
                        variant="outlined"
                        className="formTextFieldArea"
                        value={reportingHead}
                        disabled
                      />
                    </FormControl>
                  </div>
                )}
                <div className="profileFormElementGroup fromWithEdtUpldBtn">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        disabled={enablePersonalEmail}
                        label="Email Id"
                        variant="outlined"
                        className="formTextFieldArea"
                        type="email"
                        onChange={handleemailId}
                        value={personalEmailId}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="fromWithEdtUpldBtn">
                  <div className="formElement fullwidth">
                    <FormControl className="formControl">
                      <Autocomplete
                        disabled={enableLanguage}
                        multiple
                        className="formAutocompleteField"
                        variant="outlined"
                        value={userLanguageList}
                        options={languageMasterList}
                        onChange={(e, data) => {
                          if (data.length > 3) {
                            toast.error(
                              "You Cannot Select More Than 3 Languages"
                            );
                            return;
                          }
                          setUserLanguageList(data);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder="Select Languages*"
                            label="Languages"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div className="appendNewRowBtn">
                    <IconButton
                      className="elemntIconBtn"
                      onClick={(e) => {
                        if (!enableLanguage) {
                          handleLanguageSubmit();
                        } else {
                          setEnableLanguage((prev) => !prev);
                        }
                      }}
                    >
                      {!enableLanguage ? <Check /> : <EditPencil />}
                    </IconButton>
                  </div>
                </div>

                <div className="fromWithEdtUpldBtn">
                  <div className="formElement fullwidth">
                    <FormControl className="formControl">
                      {/* <Autocomplete
                      multiple
                      className="formAutocompleteField"
                      variant="outlined"
                      options={skillsList}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          placeholder="Select Skills*"
                          label="Skills"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    /> */}
                      <TextField
                        disabled={enableSkill}
                        label="Skills"
                        variant="outlined"
                        className="formTextFieldArea"
                        required
                        // value="7384979442"
                        value={skill}
                        onChange={(e) => {
                          setSkill(e.target.value);
                        }}
                        autoComplete="false"
                      />
                    </FormControl>
                  </div>
                  <div className="appendNewRowBtn">
                    <IconButton
                      className="elemntIconBtn"
                      onClick={(e) => {
                        if (!enableSkill) {
                          handelSubmitFields("skills");
                        } else {
                          setEnableSkill(false);
                          currentSkill.current = skill;
                        }
                        // setEnableMaritalStatus((prev) => !prev);
                      }}
                    >
                      {!enableSkill ? <Check /> : <EditPencil />}
                    </IconButton>
                    {/* {enableSkill && (
          <Tooltip title="Click the pencil icon to edit">
            <Typography variant="caption" color="error">
              Please click on the pencil icon to edit and click again on the tick icon to save.
            </Typography>
          </Tooltip>
        )} */}
                  </div>
                </div>

                <div className="profileFormElementGroup fromWithEdtUpldBtn">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="setbirth" className="setCompletionDate">
                        Date of Birth
                      </InputLabel>
                      <ReactDatePicker
                        toggleCalendarOnIconClick
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        maxDate={new Date()}
                        showIcon
                        labelId="setbirth"
                        className="formDatePicker"
                        label=" Date of Birth"
                        dateFormat="dd-MMM-yyyy"
                        selected={startDOB}
                        onChange={(date) => setStartDOB(date)}
                        disabled={enableDateOfBirth}
                        inline={showDobInline}
                      />
                    </FormControl>
                  </div>
                  <div className="appendNewRowBtn">
                    <IconButton
                      className="elemntIconBtn"
                      onClick={(e) => {
                        if (!enableDateOfBirth) {
                          handelSubmitFields("dateOfBirth");
                        } else {
                          setEnableDateOfBirth((prev) => !prev);
                        }
                      }}
                    >
                      {!enableDateOfBirth ? <Check /> : <EditPencil />}
                    </IconButton>
                  </div>
                </div>

                <div className="profileFormElementGroup fromWithEdtUpldBtn">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectMaritalStatus">
                        Marital Status
                      </InputLabel>
                      <Select
                        disabled={enableMeritalStatus}
                        className="formInputField"
                        variant="outlined"
                        labelId="selectMaritalStatus"
                        id="MaritalStatus"
                        value={maritalStatus}
                        label="Marital Status"
                        onChange={handlemaritalStatus}
                      >
                        <MenuItem value={""}>
                          --Select Marital Status--
                        </MenuItem>

                        <MenuItem value={"Single"}> Single</MenuItem>
                        <MenuItem value={"Married"}> Married </MenuItem>
                        <MenuItem value={"Divorced"}> Divorced </MenuItem>
                        <MenuItem value={"Widowed"}> Widowed </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="appendNewRowBtn">
                    <IconButton
                      className="elemntIconBtn"
                      onClick={(e) => {
                        if (!enableMeritalStatus) {
                          handelSubmitFields("martialStatus");
                        } else {
                          setEnableMaritalStatus((prev) => !prev);
                        }
                      }}
                    >
                      {!enableMeritalStatus ? <Check /> : <EditPencil />}
                    </IconButton>
                  </div>
                </div>

                <div className="profileFormElementGroup fromWithEdtUpldBtn">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        disabled={enablePersonalContact}
                        label="Personal Contact No"
                        variant="outlined"
                        className="formTextFieldArea"
                        // value="7384979442"
                        value={personalContactNo}
                        onChange={(e) =>
                          handlePersonalContactNo(e.target.value)
                        }
                        autoComplete="false"
                        inputProps={{ maxLength: 15 }}
                      />
                    </FormControl>
                  </div>

                  {/* <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectPhoneNumber">
                        Number Type
                      </InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectPhoneNumber"
                        id="phone_number"
                        value={selectNumber}
                        label="Number Type"
                        onChange={handlePhoneNumber}
                      >
                        <MenuItem value={"Mobile"}> Mobile</MenuItem>
                        <MenuItem value={"Personal"}> Personal </MenuItem>
                        <MenuItem value={"Office"}> Office </MenuItem>
                        <MenuItem value={"Home"}> Home </MenuItem>
                      </Select>
                    </FormControl>
                  </div> */}

                  <div className="appendNewRowBtn">
                    <IconButton
                      className="elemntIconBtn"
                      onClick={(e) => {
                        if (!enablePersonalContact) {
                          handelSubmitFields("personalContactNo");
                        } else {
                          setEnablePersonalContact((prev) => !prev);
                        }
                      }}
                    >
                      {!enablePersonalContact ? <Check /> : <EditPencil />}
                    </IconButton>
                  </div>
                </div>

                {loginUserDetails.orgId !== "ORG000000000000" && (
                  <div className="profileFormElementGroup fromWithEdtUpldBtn">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          required
                          disabled={enableOfficialContact}
                          label="Official Contact No"
                          variant="outlined"
                          className="formTextFieldArea"
                          value={officialContactNo}
                          onChange={(e) =>
                            handleOfficialContactNo(e.target.value)
                          }
                          autoComplete="false"
                          inputProps={{ maxLength: 15 }}
                        />
                      </FormControl>
                    </div>
                    <div className="appendNewRowBtn">
                      <IconButton
                        className="elemntIconBtn"
                        onClick={(e) => {
                          if (!enableOfficialContact) {
                            handelSubmitFields("officialContactNo");
                          } else {
                            setEnableOfficialContact((prev) => !prev);
                          }
                        }}
                      >
                        {!enableOfficialContact ? <Check /> : <EditPencil />}
                      </IconButton>
                    </div>
                  </div>
                )}

                {/* {loginUserDetails.orgId !== "ORG000000000000" && (
                  <div className="profileFormElementGroup fromWithEdtUpldBtn">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          disabled
                          label="Official Email Id"
                          variant="outlined"
                          className="formTextFieldArea"
                          value={Officialemail}
                          autoComplete="false"
                          inputProps={{ maxLength: 15 }}
                        />
                      </FormControl>
                    </div>
                  </div>
                )} */}

                <div className="profileFormElementGroup fromWithEdtUpldBtn">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        disabled={enableEmergencyContact}
                        label="Emergency Number"
                        variant="outlined"
                        className="formTextFieldArea"
                        value={emergencyContactNo}
                        onChange={handleEmergencyNumberChange}
                      />
                    </FormControl>
                  </div>
                  <div className="appendNewRowBtn">
                    <IconButton
                      className="elemntIconBtn"
                      onClick={(e) => {
                        if (!enableEmergencyContact) {
                          handelSubmitFields("emergencyContactNo");
                        } else {
                          setEnableEmergencyContract((prev) => !prev);
                        }
                      }}
                    >
                      {!enableEmergencyContact ? <Check /> : <EditPencil />}
                    </IconButton>
                  </div>
                </div>

                {showResetPassContainer && (
                  <div className="resetPasswordContainer">
                    <div className="resetPasswordChange">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="Old-password">
                            Old Password
                          </InputLabel>
                          <OutlinedInput
                            className="formTextFieldArea areaPassword"
                            labelId="Old-password"
                            type={showOldPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showOldPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Old Password"
                            value={oldPassword}
                            onChange={(e) => {
                              setOldPassword(e.target.value);
                            }}
                            autoComplete="new-password"
                          />
                        </FormControl>
                      </div>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="new-password">
                            New Password
                          </InputLabel>
                          <OutlinedInput
                            className="formTextFieldArea areaPassword"
                            labelId="new-password"
                            type={showNewPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowNewPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showNewPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                            value={newPassword}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                            }}
                            onPaste={handlePaste}
                          />
                        </FormControl>
                      </div>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="new-password">
                            Confirm Password
                          </InputLabel>
                          <OutlinedInput
                            className="formTextFieldArea areaPassword"
                            labelId="new-password"
                            type={showConfirmPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                            value={confirmnPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                            onPaste={handlePaste}
                          />
                        </FormControl>
                      </div>
                    </div>

                    <div className="resetPassPolicy">
                      <div className="passpollicyHead">Password policy</div>
                      <div className="passPolicyList">
                        <ul>
                          <li>
                            <div className="policyInd">
                              {matchNewAndConfirmPassword && (
                                <span className="passMatchIcon">
                                  <Check />
                                </span>
                              )}
                              {!matchNewAndConfirmPassword && (
                                <span className="passMissMatchIcon">
                                  <Close />
                                </span>
                              )}
                              <span className="passPolicyText">
                                New and Confirm Password should match.
                              </span>
                            </div>
                          </li>

                          <li>
                            <div className="policyInd">
                              {hasCapital && (
                                <span className="passMatchIcon">
                                  <Check />
                                </span>
                              )}
                              {!hasCapital && (
                                <span className="passMissMatchIcon">
                                  <Close />
                                </span>
                              )}
                              <span className="passPolicyText">
                                At least one uppercase letter.
                              </span>
                            </div>
                          </li>

                          <li>
                            <div className="policyInd">
                              {hasSmallCharacter && (
                                <span className="passMatchIcon">
                                  <Check />
                                </span>
                              )}
                              {!hasSmallCharacter && (
                                <span className="passMissMatchIcon">
                                  <Close />
                                </span>
                              )}
                              <span className="passPolicyText">
                                At least one lowercase letter.
                              </span>
                            </div>
                          </li>

                          <li>
                            <div className="policyInd">
                              {hasSpecialCharacter && (
                                <span className="passMatchIcon">
                                  <Check />
                                </span>
                              )}
                              {!hasSpecialCharacter && (
                                <span className="passMissMatchIcon">
                                  <Close />
                                </span>
                              )}
                              <span className="passPolicyText">
                                At least one special character.
                              </span>
                            </div>
                          </li>

                          <li>
                            <div className="policyInd">
                              {hasNumber && (
                                <span className="passMatchIcon">
                                  <Check />
                                </span>
                              )}
                              {!hasNumber && (
                                <span className="passMissMatchIcon">
                                  <Close />
                                </span>
                              )}
                              <span className="passPolicyText">
                                At least one number character.
                              </span>
                            </div>
                          </li>

                          <li>
                            <div className="policyInd">
                              {passwordCorrectLength && (
                                <span className="passMatchIcon">
                                  <Check />
                                </span>
                              )}
                              {!passwordCorrectLength && (
                                <span className="passMissMatchIcon">
                                  <Close />
                                </span>
                              )}
                              <span className="passPolicyText">
                                Password between 8 and 20 characters.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                <div className="resetPasswordBtn">
                  <div className="taskVDContBtn">
                    {showResetPassBtn && (
                      <Button
                        variant="outlined"
                        onClick={handelShowResetPassContainer}
                      >
                        Reset Password
                      </Button>
                    )}

                    {showUpdatePassBtn && (
                      <Button
                        variant="contained"
                        onClick={handelUpdateAndshowResetpassBtn}
                      >
                        Update Password
                      </Button>
                    )}
                  </div>
                </div>

                {/* signupload area start */}
                <div className="profileFormElementGroup fromWithEdtUpldBtn">
                  <div className="signatureUploadArea">
                    {!Signature && (
                      <HtmlTooltip title="Allowed File PNG, JPG, or JPEG format and size range of 2 KB to 200 KB. Image dimension, height 50 px to 512 px, with background either transparent or white.">
                        <div
                          className="noSignAvl"
                          onClick={() => signImageRef.current.click()}
                        >
                          <div className="noSignAvlTxt">No sign avalable!!</div>
                          <div className="btnTouploadSign">
                            Click here to upload sign
                          </div>
                        </div>
                      </HtmlTooltip>
                    )}
                    <input
                      ref={signImageRef}
                      type="file"
                      hidden
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => {
                        handleUpdateSignature(e);
                        signImageRef.current.value = null;
                      }}
                    />
                    {Signature && (
                      <>
                        <div className="signAvl">
                          {/* {console.log('signature data link' , Signature)}                */}

                          <img
                            src={Signature}
                            alt="Signature"
                            onError={(e) => {
                              console.error(
                                "Error loading image:",
                                e.target.src
                              );
                            }}
                          />
                        </div>

                        <SignStamp Signature={Signature} />

                        {/* <div className="signPreviewWithStamp">
                          <svg width="150" height="150">
                            <circle
                              cx="75"
                              cy="75"
                              r="74"
                              fill="none"
                              stroke="#3301ca"
                              stroke-width="2"
                            />
                            <circle
                              cx="75"
                              cy="75"
                              r="74"
                              fill="none"
                              stroke="#3301ca"
                              stroke-width="2"
                            />
                            <circle
                              cx="75"
                              cy="75"
                              r="54"
                              fill="none"
                              stroke="#3301ca"
                              stroke-width="1"
                            />
                            <circle
                              cx="75"
                              cy="75"
                              r="52"
                              fill="none"
                              stroke="#3301ca"
                              stroke-width="1"
                            />

                          
                            <defs>
                              <path
                                id="text-path"
                                d="M 75, 74 
                                   m -60, 0
                                   a 60,60 0 0,1 120,0
                                   a 60,60 0 0,1 -120,0"
                                fill="none"
                              />

                              <path
                                id="text-path2"
                                d="M 75, 83  m -60, 0  a 60,60 0 1,0 120,0  a 60,60 0 1,0 -120,0"
                                fill="none"
                              />
                            </defs>

                  

                            <text>
                              <textPath
                                href="#text-path"
                                startOffset="25%"
                                textAnchor="middle"
                                fontFamily="Inter"
                                fontSize="10"
                                fontWeight="600"
                                fill="#3301ca"
                                letterSpacing="0"
                                x="60"
                                y="40"
                              >
                                ★ Minion Technologies Pvt. Ltd. ★
                              </textPath>
                            </text>
                            <text>
                              <textPath
                                href="#text-path2"
                                startOffset="25%"
                                text-anchor="middle"
                                fontFamily="Inter"
                                font-size="10"
                                font-weight="600"
                                fill="#3301ca"
                                letterSpacing="0"
                                direction="ltr"
                              >
                                CIN: 7015424575DM
                              </textPath>
                            </text>

                           
                            <image
                              x="40"
                              y="20"
                              width="52"
                              height="52"
                              href={Signature}
                            />

                           
                            <text
                              x="50%"
                              y="55%"
                              font-family="Inter"
                              font-size="10"
                              text-anchor="middle"
                              fill="#3301ca"
                            >
                              {formattedDate}
                            </text>

                           
                            <text
                              x="50%"
                              y="65%"
                              font-family="Inter"
                              font-size="10"
                              text-anchor="middle"
                              fill="#3301ca"
                            >
                              {formattedTime}
                            </text>
                            <text
                              x="50%"
                              y="75%"
                              font-family="Inter"
                              font-size="10"
                              text-anchor="middle"
                              fill="#3301ca"
                            >
                              GMT +5.30
                            </text>
                          </svg>
                        </div> */}
                      </>
                    )}
                  </div>
                </div>
                {Signature && (
                  <div className="formBtnElement">
                    <HtmlTooltip title="Allowed File PNG, JPG, or JPEG format and size range of 2 KB to 200 KB. Image dimension, height 50 px to 512 px, with background either transparent or white.">
                      <Button
                        className="comBtnOutline"
                        startIcon={<Autorenew /> /*<Done/>*/}
                        onClick={() => signImageRef.current.click()}
                      >
                        Re-Upload Sign
                      </Button>
                    </HtmlTooltip>
                  </div>
                )}
                {/* signupload area end */}
              </div>
            </div>
          </div>
          {/* <div className="profileFooter">
            <div className="formBtnElement">
              <Button className="dfultPrimaryBtn " onClick={closeprofile}>
                Save
              </Button>
            </div>
          </div> */}
        </div>
        {!enableUserName && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">User Full Name</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      label="First Name"
                      variant="outlined"
                      className="modelTextFild"
                      multiline
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      // value={userProfileDto && userProfileDto.employeeId}
                      // {...register("employeeId")}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      label="Second Name"
                      variant="outlined"
                      className="modelTextFild"
                      multiline
                      value={middleName}
                      onChange={(e) => {
                        setMiddleName(e.target.value);
                      }}
                      // value={userProfileDto && userProfileDto.employeeId}
                      // {...register("employeeId")}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      className="modelTextFild"
                      multiline
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      // value={userProfileDto && userProfileDto.employeeId}
                      // {...register("employeeId")}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  startIcon={<CheckCircle />}
                  onClick={(e) => {
                    console.log("changing user name ");
                    if (!enableUserName) {
                      handelSubmitFields("userName");
                      // setEnableUserName((prev) => !prev);
                    } else {
                      setEnableUserName((prev) => !prev);
                    }
                  }}
                >
                  Update Name
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setEnableUserName((prev) => !prev);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )}
      </div>
      {showUploadProfileModal && console.log("profile upload on")}
      {!showUploadProfileModal && console.log("profile upload off")}
      {showUploadProfileModal && (
        <UploadProfileImage
          closeUploadModal={() => setShowUploadProfileModal(false)}
          setUserProfile={(data) => {
            const temp = { ...userProfileDto };
            temp.profileImageFileDataLink = data;
            setUserProfileDto(temp);
            refreshUserData();
          }}
        />
      )}

      {showRegFace && (
        <ConfirmProvider>
          <FaceCaptureComponent
            open={showRegFace}
            onClose={() => setShowRegFace(false)}
            faceId={faceId}
            setFaceId={setFaceId}
            setShowRegFace={setShowRegFace}
          />
        </ConfirmProvider>
      )}
    </>
  );
}
